'use client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IApiCategoryMapped } from '@/types/interfaces/aggregated';
import { IApiCategory } from '@/types/interfaces/categories';
import cn from "@/libs/cn";
import { IApiStore } from '@/types/interfaces/stores';
import useClientContext from '@/context/ClientContext';
import useClientRouter from '@/hooks/useClientRouter';
import { useDataPublicStoreActiveCategories } from '../../hooks/useData';
import InlineIcons from '@/components/basic/InlineIcons';
import LoaderCircle from '@/controls/LoaderCircle';
import { CATEGORY_PARAM_NAME } from '../../constants/data';
import publicRequests from '../../requests';

import styles from './MobileStoreCategoryFilters.module.scss';

interface MobileStoreCategoryFiltersProps {
  className: string;
  store: IApiStore;
  category: IApiCategory | null;
}

const MobileStoreCategoryFilters = (props: MobileStoreCategoryFiltersProps) => {
  const { className, store, category } = props;
  const { translate, openModal, closeModal } = useClientContext();
  const router = useClientRouter();
  const { pathname, query } = router;

  const onSelectCategory = useCallback(async (category: IApiCategory | null) => {
    closeModal();
    if (category) {
      const breadcrumbs = await publicRequests.categoryBreadcrumbs(category.id);

      router.push(
        {
          pathname,
          query: { ...query, [CATEGORY_PARAM_NAME]: breadcrumbs?.map((i) => i.slug).join(',') },
        },
        { scroll: true }
      );
      return;
    }
    const newQuery = { ...query };
    delete newQuery[CATEGORY_PARAM_NAME];

    router.push(
      {
        pathname,
        query: newQuery,
      },
      { scroll: true }
    );

    scroll;
  }, [closeModal, pathname, query, router]);

  const openModalCategoryFilters = useCallback(() => {
    openModal(() => <CategoryList store={store.id} category={category} onSelect={onSelectCategory} />, {
      title: '',
      className: styles.modal,
      classNameHeader: styles.modalHeader,
    });
  }, [category, onSelectCategory, openModal, store.id]);
  return (
    <div className={cn(styles.MobileStoreCategoryFilters, className)} onClick={openModalCategoryFilters}>
      <span>{category ? category?.name : translate('All categories')}</span>
      {/* {category && category.child.length > 0 && <InlineIcons.ArrowDown width={24} height={24} />} */}
      <InlineIcons.ArrowDown width={24} height={24} />
    </div>
  );
};

interface CategoryListProps {
  store?: number;
  category?: IApiCategory | null;
  onSelect: (category: IApiCategory) => void;
}

export const CategoryList = (props: CategoryListProps) => {
  const { store, category, onSelect } = props;
  const { translate } = useClientContext();
  // @ts-ignore
  const [current, setCurrent] = useState<IApiCategoryMapped | null>(category);

  const categories = useDataPublicStoreActiveCategories(store);

  const options = useMemo(() => {
    return categories.data.filter((i) => (current ? i.parent == current.id : i.parent == null));
  }, [categories.data, current]);

  useEffect(() => {
    // @ts-ignore
    if (categories.data.length > 0 && (current?.has_children === false || current?.child?.length === 0)) {
      setCurrent(categories.data.find((i) => i.id == current.parent) || null);
    }
    // @ts-ignore
  }, [categories?.data, current?.child?.length, current?.has_children, current?.parent]);

  return (
    <div className={styles.CategoryList}>
      {categories.isLoading ? (
        <div className={styles.loader}>
          <LoaderCircle stretchToSpace />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            {!!current && (
              <InlineIcons.ArrowShortLeft
                width={24}
                height={24}
                onClick={() => {
                  setCurrent(categories.data.find((i) => i.id === current?.parent) || null);
                }}
              />
            )}
            <div>
              {current ? (
                // @ts-ignore
                <>
                  <span>{current.name}</span>
                  &nbsp;{`(${categories.data.find((i) => i.id == current.id)?.doc_count || 0})`}
                </>
              ) : (
                // @ts-ignore
                translate('All categories ({COUNT})', { COUNT: options.length })
              )}
            </div>
          </div>
          {/* @ts-ignore */}
          <div className={styles.current} onClick={() => onSelect(current)}>
            {!current ? translate('-(mobile-modal-filter)-All categories') : translate('-(mobile-modal-filter)-All in this category')}
          </div>
          <div className={styles.list}>
            {options.map((item) => {
              return (
                <div
                  key={item.id}
                  className={styles.item}
                  onClick={() => {
                    if (item.has_children) {
                      setCurrent(item);
                    } else {
                      // @ts-ignore
                      onSelect(item);
                    }
                  }}
                >
                  <div>{item.name}</div>
                  {item.has_children && <InlineIcons.ArrowRight width={24} height={24} />}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default MobileStoreCategoryFilters;
