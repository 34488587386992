"use client";
import cn from "@/libs/cn";
import LinkCategory from "../LinkCategory";

import styles from "./styles.module.scss";

interface FoundIntoTopCategories {
  className?: string;
  data: {
    id: number;
    parent: number | null;
    icon: string | null;
    name: string;
    level: number;
    slug: string;
    doc_count: number;
    description: string | null;
    has_children: boolean;
  }[];
  currentId?: any;
  q?: string;
}

const FoundIntoTopCategories = (props: FoundIntoTopCategories) => {
  const { data, currentId, q, className } = props;

  return (
    <nav className={cn(styles.FoundIntoTopCategories, className)}>
      {data
        .sort((a, b) => (a.level !== b.level ? (a.level > b.level ? 1 : -1) : 0))
        .map((item) => {
          const isCurrent = item.id == currentId;

          return <LinkCategory key={item.id} category={item} q={q} isCurrent={isCurrent} />;
        })}
    </nav>
  );
};

export default FoundIntoTopCategories;
